<template>
  <img class="top" src="../assets/images/qybTop1.png" />
  <div class="model">
    <span class="modelTitle">服务内容</span>
    <div class="detail">
      <p class="disItem">
        <strong>家庭医生：</strong>
        由具备至少5年临床工作经验的自有全科医生担任患者管理计划统筹管理者，协助被保险人提供相关病例资料，建立个人健康档案，并进行持续的健康指标监测以及健康风险评估，根据被保险人的病情及需求，为其提供日常健康咨询、用药指导以及健康教育，并协同主管医生制定风险窗口期的患者管理计划。
      </p>
      <p class="disItem">
        <strong>主管医生：</strong>
        三甲医院在职医生通过图文方式为被保险人提供一对一的日常疾病问诊、报告解读、健康指导、症状管理、就医指导、用药指导术后营养康复指导等医疗咨询，利用专科医生的临床优势，提供系统性、针对性的医疗咨询服务，对客户的健康进行全面管理，并与家庭医生协同制定患者管理计划。
      </p>
      <p class="disItem">
        <strong>远程会诊：</strong>
        针对病情复杂，术后康复过程缓慢的被保险人，可由家庭医生根据实际情况，为其协调安排北、上、广、深、成等医疗资源发达地区三甲医院副主任及以上的相关专家，为客户进行远程多学科会诊，并输出专业的诊疗方案。
      </p>
      <p class="disItem">
        <strong>康复师：</strong>
        三甲医院在职护士通过电话、图文、视频方式为被保险人提供一对一的健康指导、症状管理、营养康复指导等医疗咨询，并为其制定上门康复管理计划，保障患者享受最佳的术后康复环境。
      </p>
      <p class="disItem">
        <strong>院后护理：</strong>
        根据康复师制定的康复计划，为患者协调安排专业康复师进行上门服务。服务内容包括：伤口换药、外科伤口拆线、上门打针、静脉采血、压疮护理及指导、留置胃管、留置导尿、灌肠护理及指导、雾化护理、吸痰护理及指导、造口护理、PICC护理、膀胱冲洗、口腔护理、心脑血管疾病护理及指导。
      </p>
      <p class="disItem">
        根据康复师制定的康复计划，为患者协调安排专业康复师进行上门服务：<br />
        1. 根据康复计划要求，协助患者进行日常康复训练；<br />
        2.
        对患者进行生活照护、饮食照护、排泄照护、专业护理、安全用药指导、风险防范等。
      </p>
      <p class="disItem">
        <strong>智能监测设备：</strong>
        根据被保险人的病情及需求，为其提供7*24小时健康数据监测智能设备，包括日间可穿戴设备（1台）、睡眠监测仪（1台），患者健管指标数据及行为数据实时回传，根据患者身体指标变化情况，进行后期管理计划的适当调整，以保障最佳康复效果。
      </p>
      <p class="disItem">
        <strong>康复用品：</strong>
        联合知名医疗器械供应商，建立康复用品及辅助器具生态体系，保障患者在术后康复期内所需用的一切康复用品及辅助器具需求。
      </p>
      <p class="disItem">
        <strong>康复知识库：</strong>
        自建康复、护理、疾病、养生健康知识素材库，可根据被保险人罹患的不同病种所需，进行知识的定向推送。
      </p>
    </div>
  </div>
  <div class="model">
    <span class="modelTitle">服务区域</span>
    <div class="detail">
      <p class="disItem">线上服务，不限区域。</p>
    </div>
  </div>
  <div class="model">
    <span class="modelTitle">服务方式及流程</span>
    <div class="detail">
      <p class="disItem">
        由客户本人通过“珊瑚健康管家“微信公众号，根据服务申请提示，填写必要的个人信息、上传由公立医院出具的诊断证明，审核通过后，将在第一时间内为客户安排服务。
      </p>
    </div>
  </div>

  <div class="bntBox">
    <span @click="getService" class="getService">立即使用服务</span>
    <span @click="goRecord" class="record">我的订单</span>
  </div>
  <Alert v-if="alertFlag" :alertData="alertData" @sure="alertSure"></Alert>
</template>
<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { AlertData } from "../components/interface/Alert-d";
import waringImg from "../assets/images/waring.png";
const router = useRouter();
const store = useStore();

let queryData = {
  policyNo: router.currentRoute.value.query.policyNo,
  userName: router.currentRoute.value.query.userName,
};
store.commit("upQuery", queryData);
sessionStorage.setItem("upQuery", JSON.stringify(queryData));
const alertData = reactive<AlertData>({
  alertTitle: "温馨提示",
  alertContent: "111",
  bntText: "我知道了",
  pwidth: 525,
  alertType: "alert",
  icon: waringImg,
});
const alertFlag = ref<boolean>(false);
const checkFlag = ref(false);
const check = () => {
  checkFlag.value = !checkFlag.value;
};

const linkfor = (url: string) => {
  router.push(url);
};
const subFlag = ref(true);
const getService = () => {
  if (!subFlag.value) return;
  subFlag.value = false;
  store
    .dispatch("useService")
    .then((res) => {
      sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      router.push({ path: "/apply", query: { type: "frHome" } });
      subFlag.value = true;
    })
    .catch(() => {
      subFlag.value = true;
    });
};
const goRecord = () => {
  router.push("/recordList");
};

const alertSure = () => {
  alertFlag.value = false;
};
</script>
<style scoped>
.top {
  display: block;
  width: 100%;
}
.model {
  background: #fff;
  overflow: hidden;
  margin-bottom: 2.67vw;
  padding-bottom: 6vw;
}
.modelTitle {
  margin: 3vw auto;
}
.modelTitle:before {
  background: #49aeff;
}
.detail {
  width: 89.33vw;
  border: 0.13vw solid #d8d8d8;
  margin: auto;
}
.detail .disItem:last-child {
  border-bottom: none;
}
.detail strong {
  color: #000;
}
.detail .disItem {
  width: 82.27vw;
  margin: auto;
  font-size: 3.2vw;
  line-height: 4.27vw;
  color: #666;
  padding: 2.4vw 0;
  border-bottom: #d8d8d8 solid 1px;
}

.detailBox {
  position: relative;
  margin-top: 8vw;
}
.getHospitalList {
  position: absolute;
  width: 77.6vw;
  height: 11.6vw;
  top: 48.2vw;
  left: 50%;
  margin-left: -38.8vw;
}
.agreeBox {
  width: 76vw;
  line-height: 6vw;
  margin: 5.8vw auto 5.33vw auto;
}
.agreeBox span,
.agreeBox a {
  display: block;
  font-size: 3.47vw;
}
.agreeBox span {
  color: #666666;
}
.agreeBox a {
  color: #01b6ff;
  /* text-decoration: underline; */
}
.check {
  position: relative;
}
.check::after {
  position: absolute;
  height: 5.2vw;
  width: 5.2vw;
  background: url(../assets/images/check.png) no-repeat;
  background-size: 5.2vw 5.2vw;
  display: block;
  content: "";
  left: -7vw;
  top: 0.5vw;
}
.check.checked::after {
  background: url(../assets/images/checked.png) no-repeat;
  background-size: 5.2vw 5.2vw;
}
.bntBox {
  width: 96vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 9.37vw;
}
.bntBox span {
  display: block;
  width: 36vw;
  height: 11.93vw;
  text-align: center;
  line-height: 11.93vw;
  font-size: 4.27vw;
  font-weight: bold;
  color: #fff;
  border-radius: 1.33vw;
  border: 0.13vw solid #ffffff;
  box-shadow: 0 1vw 0 0 rgba(7, 0, 2, 0.08);
  background: linear-gradient(0deg, #1a98ff, #63ecfd);
}
.bntBox span.getService {
  width: 53.3vw;
}
.bntBox span.getService.disabled {
  opacity: 0.5;
}
</style>
